<template>
  <div class="footer--find">
    <div class="footer--title mb-3">
      {{ $t('footer.find.header') }}
    </div>
    <div class="d-flex flex-wrap mb-3">
      <router-link to="/directory/hdb" class="footer--nav mb-2">
        {{ $t('footer.find.hdb') }}
      </router-link>
      <router-link to="/directory/condo" class="footer--nav mb-2">
        {{ $t('footer.find.condo') }}
      </router-link>
      <router-link to="/directory/shophouse" class="footer--nav mb-2">
        {{ $t('footer.find.shophouse') }}
      </router-link>
      <router-link to="/directory/office" class="footer--nav mb-2">
        {{ $t('footer.find.office') }}
      </router-link>
      <router-link to="/directory/retail" class="footer--nav mb-2">
        {{ $t('footer.find.retail') }}
      </router-link>
      <router-link to="/directory/factory" class="footer--nav mb-2">
        {{ $t('footer.find.factory') }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>
