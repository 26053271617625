<template>
  <footer class="footer--container">
    <div class="container d-flex flex-wrap">
      <div class="column--1">
        <div class="footer--logo">
          <router-link to="/">
            <img :src="logoUrl" alt="Propfix Logo" />
          </router-link>
        </div>
        <div class="footer--social">
          <a href="https://www.facebook.com/propfix.sg" target="_blank">
            <div class="btn btn-primary mr-4">
              <img src="@/assets/img/icons/facebook.svg" />
            </div>
          </a>
          <a href="https://instagram.com/propfix.sg" target="_blank">
            <div class="btn btn-primary">
              <img src="@/assets/img/icons/instagram.svg" alt="Instagram" />
            </div>
          </a>
        </div>
        <!--        <div class="footer&#45;&#45;about"></div>-->
        <!--        <div class="footer&#45;&#45;language">-->
        <!--          <v-select-->
        <!--            :value="locale"-->
        <!--            @input="setLang"-->
        <!--            :items="locales"-->
        <!--            item-value="id"-->
        <!--            item-text="name"-->
        <!--            :label="$t('general.language')"-->
        <!--            class="basic&#45;&#45;dropdown"-->
        <!--            solo-->
        <!--            hide-details-->
        <!--          ></v-select>-->
        <!--        </div>-->
        <div class="footer--copyright">
          {{ $t('footer.copyright1') }}
          {{ new Date().getFullYear() }}
          {{ $t('footer.copyright2') }}
        </div>
      </div>
      <div class="column--2">
        <div class="footer--title mb-3">
          {{ $t('footer.company.header') }}
        </div>
        <div class="d-flex flex-wrap mb-3">
          <!--          <router-link to="/news" class="footer&#45;&#45;nav col-6 p-0 mb-2">-->
          <!--            {{ $t('footer.company.news') }}-->
          <!--          </router-link>-->
          <router-link to="/help" class="footer--nav col-6 p-0 mb-2">
            {{ $t('footer.company.helpCenter') }}
          </router-link>
          <router-link to="/term-of-service" class="footer--nav col-6 p-0 mb-2">
            {{ $t('footer.company.tos') }}
          </router-link>
          <router-link to="/privacy-policy" class="footer--nav col-6 p-0 mb-2">
            {{ $t('footer.company.privacy') }}
          </router-link>
        </div>
        <div class="footer--column-wrapper">
          <div class="footer--country-mobile">
            <div class="footer--country">
              <div class="footer--title mb-3">
                {{ $t('footer.country.header') }}
              </div>
              <a href="https://rentfix.com" target="_blank" class="footer--nav mb-3">
                {{ $t('footer.country.id') }}
              </a>
              <br />
              <a href="#" class="footer--nav mb-3">
                Singapore
              </a>
            </div>
            <div class="footer--mobile mt-3">
              <a
                href="https://play.google.com/store/apps/details?id=com.rentfix.sg"
                target="_blank"
              >
                <img src="@/assets/img/home/google-play-badge.svg" alt="Google Play Store" />
              </a>
              <a href="https://itunes.apple.com/id/app/rentfix-sg/id1552345802" target="_blank">
                <img src="@/assets/img/home/badges-app-store.svg" alt="Apple App Store" />
              </a>
            </div>
          </div>
          <footer-find v-show="$mq === 'xs'"></footer-find>
        </div>
      </div>
      <div class="column--3">
        <footer-find v-show="$mq !== 'xs'"></footer-find>
        <!--        <div class="footer&#45;&#45;title mb-3">-->
        <!--          {{ $t('footer.payment') }}-->
        <!--        </div>-->
        <!--        <div class="footer&#45;&#45;payment-partner">-->
        <!--        <img src="@/assets/img/bank/alto.png" alt="Alto" />-->
        <!--        <img src="@/assets/img/bank/visa.svg" alt="Visa" />-->
        <!--        <img src="@/assets/img/bank/master.svg" alt="Mastercard" />-->
        <!--        <img src="@/assets/img/bank/atm-bersama.png" alt="ATM Bersama" />-->
        <!--        <img src="@/assets/img/bank/midtrans.png" alt="Midtrans" />-->
        <!--        <img src="@/assets/img/bank/permata.svg" alt="Permata Bank" />-->
        <!--        <img src="@/assets/img/bank/bni.svg" alt="BNI" />-->
        <!--        </div>-->
      </div>
    </div>
  </footer>
</template>

<script>
import FooterFind from '@/components/layouts/partials/footer-find';
import { mapState } from 'vuex';
export default {
  components: {
    FooterFind,
  },
  computed: {
    ...mapState({
      logoUrl: state => state.global.logoUrl,
      locales: state => state.global.locales,
      locale: state => state.global.locale,
    }),
  },
  methods: {
    setLang(locale) {
      this.$store.commit('global/SET_LANG', locale);
      this.$router.go(0);
    },
  },
};
</script>
